import React from "react";
import styled from "styled-components";
import { theme } from "../../style/theme";
import { themeGet } from "@styled-system/theme-get";

import { Item } from "./Item";

const Wrapper = styled.div`
  width: 100%;
  padding: 0 32px;
  background-color: ${theme.colors.EYOffBlack.default};
`;

const Inner = styled.div`
  width: 100%;

  @media screen and (min-width: ${themeGet("breakpoints.xl")}) {
    max-width: 1216px;
    margin: 0 auto;
  }
`;

const Title = styled.h2`
  color: ${theme.colors.EYYellow.default};
  font-size: 24px;
  line-height: 40px;
  font-weight: 300;

  @media screen and (min-width: ${themeGet("breakpoints.lg")}) {
    font-size: 32px;
  }
`;

const List = styled.div`
  width: 100%;
  margin-top: 32px;

  & > * {
    padding: 16px 0 20px 0;
    border-top: 1px solid ${theme.colors.EYGrey01};

    &:last-child {
      border-bottom: 1px solid ${theme.colors.EYGrey01};
    }
  }

  @media screen and (min-width: ${themeGet("breakpoints.lg")}) {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > * {
      width: calc(50% - 26px) !important;

      ${({ count }) =>
        count &&
        count > 1 &&
        `
        &:nth-child(${count - 1}) {
          border-bottom: 1px solid ${theme.colors.EYGrey01};
        }
      `}
    }
  }
`;

const CertificationFloor = ({ title, items }) => {
  if (items.length === 0) return <></>;

  return (
    <Wrapper>
      <Inner>
        <Title>{title}</Title>
        <List count={items.length}>
          {items.map((e, i) => (
            <Item key={i} {...e} />
          ))}
        </List>
      </Inner>
    </Wrapper>
  );
};

export { CertificationFloor };
