import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../style/theme";
import { space, typography } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
import Button from "../Button";

const Wrapper = styled.div`
  width: 100%;
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (pointer: fine) {
    &:hover {
      .mainButton {
        transform: rotate(90deg);
      }
    }
  }
`;

const MainTitle = styled.div`
  display: flex;
  flex-grow: 1;
  padding-right: 32px;
`;

const Title = styled.div`
  flex-shrink: 0;
  color: ${theme.colors.white["100"]};
  font-weight: 300;

  ${space}
  ${typography}
`;

const MainButton = styled.button`
  width: 19px;
  height: 19px;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  flex-shrink: 0;
  position: relative;
  transition: transform ease-in-out 300ms;

  ${({ active }) =>
    active &&
    `
        transform: rotate(180deg) !important;

        & > *:nth-child(2) {
            opacity: 0;
        }
  `}
`;

const Line = styled.div`
  background-color: ${theme.colors.EYYellow.default};
  position: absolute;
  transition: opacity ease-in-out 300ms;

  &:nth-child(1) {
    width: 100%;
    height: 1px;
    left: 0;
    top: 9px;
  }

  &:nth-child(2) {
    width: 1px;
    height: 100%;
    left: 9px;
    top: 0;
  }
`;

const Content = styled.div`
  width: 100%;
  overflow: hidden;
  transition: height ease-in-out 400ms;
`;

const ContentInner = styled.div`
  width: 100%;
  padding-top: 8px;
`;

const Text = styled.div`
  width: 100%;
  white-space: break-spaces;
  color: ${theme.colors.white["100"]};
  font-weight: 300;
  ${space}
  ${typography}
`;

const Item = ({ title, text, button }) => {
  const ref = useRef(null);

  const [active, setActive] = useState(false);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    let timeout = null;

    const updateHeight = () => {
      if (ref && ref.current) {
        const { clientHeight } = ref.current;

        setHeight(clientHeight);
      }
    };

    updateHeight();
    timeout = setTimeout(() => updateHeight(), 100);
    window.addEventListener("resize", updateHeight, { passive: true });

    return () => {
      window.removeEventListener("resize", updateHeight);
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [ref]);

  return (
    <Wrapper>
      <Main onClick={() => setActive(!active)}>
        <MainTitle>
          <Title lineHeight={1} fontSize={3}>{title}</Title>
        </MainTitle>
        <MainButton active={active} className="mainButton">
          <Line />
          <Line />
        </MainButton>
      </Main>
      <Content style={{ height: active ? height : 0 }}>
        <ContentInner ref={ref}>
          <Text lineHeight={3} fontSize={2}>{text}</Text>
          {button && button.link && button.label && (
            <Button
              href={button.link}
              width={["100%", null, "fit-content", null, null]}
              mt={[24, null, null, null, null]}
              borderRadius={4}
            >
              {button.label}
            </Button>
          )}
        </ContentInner>
      </Content>
    </Wrapper>
  );
};

export { Item };
