import React from "react";
import { graphql } from "gatsby";

import usePageView from "../utils/usePageView";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import { EducationContent } from "../components/EducationContent";

const EducationPostTemplate = (props) => {
  usePageView(props.location.pathname, "Vzdělávání", "Education");

  const {
    data: {
      mdx: {
        frontmatter: { name, description, ...rest },
      },
    },
  } = props;

  return (
    <Layout location={props.location} title="event">
      <SEO title={name} description={description || post.excerpt} />
      <EducationContent {...rest} description={description} />
    </Layout>
  );
};

export default EducationPostTemplate;

export const pageQuery = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        name
        headerPostImage {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 90)
          }
        }
        title
        description
        certificationTitle
        certificationItems {
          title
          text
          button {
            link
            label
          }
        }
        bubbles {
          image {
            childImageSharp {
              gatsbyImageData(width: 88, height: 88, quality: 90, layout: FIXED)
            }
          }
          button {
            link
            text
          }
          title
          name
          position
          description
          department
        }
      }
    }
  }
`;
